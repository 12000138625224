import axios from 'axios'
import { baseUrl } from '../Config';

export const addProducts = async (data) => {
  try {
    return await axios.post(`${baseUrl}`, data);
  } catch (error) {
    console.log(`error while calling api ${error}`);
  }
};

export const getAllProducts = async () => {
  try {
    return await axios.get(`${baseUrl}Product`);
  } catch (error) {
    console.log(`error while calling api ${error}`);
  }
};

export const getSingleProduct = async (id) => {
  try {
    return await axios.get(`${baseUrl}Product/` + parseInt(id));
  } catch (error) {
    console.log(`error while calling api ${error}`);
  }
};

export const getProductByCategory = async (id) => {
  try {
    return await axios.get(`${baseUrl}Product/byCategory/` + parseInt(id));
  } catch (error) {
    console.log(`error while calling api ${error}`);
  }
};

export const editProduct = async (id, data) => {
  try {
    return await axios.put(baseUrl + parseInt(id.id), data);
  } catch (error) {
    console.log(`error while calling api ${error}`);
  }
};

export const deleteCategory = async (id) => {
  try {
    return await axios.delete(baseUrl + parseInt(id));
  } catch (error) {
    console.log(`error while calling api ${error}`);
  }
};
