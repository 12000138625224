import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {getAllProductsFromCartAccUSer, getSingleProductFromCart, placeOrder,} from "../Services/Cart/api";
import {user} from "../Services/Config";

function Checkout() {
    const navigate = useNavigate();
    const [products, setProducts] = useState([]);
    const [countOfProduct, setCountOfProduct] = useState(0);
    const [totalPrice, setTotalPrice] = useState(0);

    const [typeName, setTypeName] = useState("");
    const [typeText, setTypeText] = useState("");
    const [typeExp, setTypeExp] = useState("");
    const [typeCvv, setTypeCvv] = useState("");

    const handlePlaceOrder = async () => {
        for (const product of products) {
            const id = product.id
            const response = await getSingleProductFromCart(id);
            if (response.status === 200) {
                await placeOrder(response.data.id)
            }
        }
        navigate("/orders");
    };

    const getCartProducts = async (id) => {
        const response = await getAllProductsFromCartAccUSer(id);
        if (response.status === 200) {
            setProducts(response.data);
            setCountOfProduct(response.data.length);
        }
    };

    useEffect(() => {
        getCartProducts(user.id);
    }, []);

    useEffect(() => {
        // Calculate total price after products state is updated
        const totalPrice = products.reduce((acc, singleProduct) => acc + singleProduct.price, 0);
        setTotalPrice(totalPrice);
    }, [products]);

    return (
        <section className="h-100 h-custom">
            <div className="container py-5 h-100">
                <div className="row d-flex justify-content-center align-items-center h-100">
                    <div className="col-lg-8 col-sm-12">
                        <div className="card shadow">
                            <div className="card-body p-4">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <h3 className="mb-4">Checkout</h3>
                                        <hr/>
                                        <div className="mb-4">
                                            {products.map((singleProduct) => (<div
                                                key={singleProduct.id}
                                                className="d-flex justify-content-between align-items-center mb-3"
                                            >
                                                <div>
                                                    <span>{singleProduct.product.name}</span>
                                                </div>
                                                <div>
                                                    <span>Qty: {singleProduct.quantity}</span>
                                                    <span className="mx-2 text-dark">|</span>
                                                    <span>
                              Price: ₹ {singleProduct.price.toFixed(2)}
                            </span>
                                                </div>
                                            </div>))}
                                        </div>
                                        <hr/>

                                        <div className="d-flex justify-content-between mb-4">
                                            <span className="fw-bold text-dark fs-4">Total :</span>
                                            <span className="text-primary fw-bold fs-4">
                          ₹ {totalPrice.toFixed(2)}
                        </span>
                                        </div>

                                        <p className="mb-2">Payment Method: Cash on Delivery</p>

                                        <button
                                            type="button"
                                            className="btn btn-block btn-lg btn-primary"
                                            onClick={() => handlePlaceOrder()}
                                        >
                                            Confirm Order &nbsp;
                                            <i className="fas fa-long-arrow-alt-right"/>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>);
}

export default Checkout;
