import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import Products from "./components/AllProducts";
import Services from "./components/Services";
import About from "./components/About";
import Banner from "./components/Banner";
import AllProducts from "./components/AllProducts";
import Gallery from "./components/Gallery";
import ProductDetail from "./components/ProductDetail";
import Cart from "./components/Cart";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Auth from "./components/Auth";
import Checkout from "./components/Checkout";
import Profile from "./components/Profile";
import Orders from "./components/Orders";
import SingleCategory from "./components/SingleCategory";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<App />}>
          <Route index element={<Banner />} />
          <Route path="/About" element={<About />} />
          <Route path="/Services" element={<Services />} />
          <Route path="/Products" element={<Products />} />
          <Route exact
            path="/SingleCategory/:CategoryId"
            element={<SingleCategory />}
          />
          <Route path="/AllProduct" element={<AllProducts />} />
          <Route path="/Gallery" element={<Gallery />} />
          <Route path="/ProductDetail/:ProductId" element={<ProductDetail />} />
          <Route path="/Cart" element={<Cart />} />
          <Route path="/Checkout/:UserId" element={<Checkout />} />
          <Route path="/Profile" element={<Profile />} />
          <Route path="/orders" element={<Orders />} />
        </Route>
        <Route path="/Login" element={<Auth />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

reportWebVitals();
