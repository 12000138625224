import React from 'react'

export default function Services() {
  return (
    <div>
      <div className="container-fluid py-5">
  <div className="container">
    <div className="row g-5">
      <div className="col-lg-4 col-md-6">
        <div className="mb-3">
          <h6 className="text-primary text-uppercase">Services</h6>
          <h1 className="display-5">Organic Farm Services</h1>
        </div>
        <p className="mb-4">
        Indulge in the creamy goodness of our organic dairy products, crafted with care from our wholesome farm
        </p>
        <a href="/" className="btn btn-primary py-md-3 px-md-5">
          Contact Us
        </a>
      </div>
      <div className="col-lg-4 col-md-6">
        <div className="service-item bg-light text-center p-5">
          <i className="fa fa-carrot display-1 text-primary mb-3" />
          <h4>Fresh Vegetables</h4>
          <p className="mb-0">
          Indulge in our luscious dairy delights, perfectly complemented by the freshness of handpicked, farm-to-table vegetables. Savor the goodness!
          </p>
        </div>
      </div>
      <div className="col-lg-4 col-md-6">
        <div className="service-item bg-light text-center p-5">
          <i className="fa fa-apple-alt display-1 text-primary mb-3" />
          <h4>Fresh Fruits</h4>
          <p className="mb-0">
          Indulge in our luscious dairy delights, crafted with love and paired perfectly with the freshest fruits
          </p>
        </div>
      </div>
      <div className="col-lg-4 col-md-6">
        <div className="service-item bg-light text-center p-5">
          <i className="fa fa-dog display-1 text-primary mb-3" />
          <h4>Healthy Cattle</h4>
          <p className="mb-0">
          Indulge in our premium dairy delights, crafted from the milk of our happy and healthy cattle
          </p>
        </div>
      </div>
      <div className="col-lg-4 col-md-6">
        <div className="service-item bg-light text-center p-5">
          <i className="fa fa-tractor display-1 text-primary mb-3" />
          <h4>Modern Truck</h4>
          <p className="mb-0">
          Savor the creamy indulgence of our artisanal dairy products, delivering modern flavors straight to your taste buds
          </p>
        </div>
      </div>
      <div className="col-lg-4 col-md-6">
        <div className="service-item bg-light text-center p-5">
          <i className="fa fa-seedling display-1 text-primary mb-3" />
          <h4>Farming Plans</h4>
          <p className="mb-0">
          Explore our exquisite dairy products crafted with care, connecting you to nature's goodness through sustainable farming plans
          </p>
        </div>
      </div>
    </div>
  </div>
</div>

    </div>
  )
}
