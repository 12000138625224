import React, { useEffect, useState } from "react";
import {getUserProfile, updateProfile} from "../Services/Auth/profile";
import { user } from "../Services/Config";

function Profile() {
    const [profile, setProfile] = useState({
        name: "",
        email: "",
        number: "",
        address: "",
        // Add other profile fields as needed
    });

    const [editProfile, setEditProfile] = useState({
        name: "",
        email: "",
        number: "",
        address: "",
        password:""
        // Add other fields as needed
    });

    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        fetchUserProfile();
    }, []);

    const fetchUserProfile = async () => {
        try {
            const response = await getUserProfile(user.id);
            if (response.status === 200) {
                setProfile(response.data);
            }
        } catch (error) {
            console.error("Error fetching user profile:", error);
        }
    };

    const handleShowModal = () => {
        setShowModal(true);
        // Populate the editProfile state with the current profile data
        setEditProfile({
            name: profile.name,
            email: profile.email,
            number: profile.number,
            address: profile.address,
            password: profile.password,
            // Remove id from the state
        });
    };

    const handleCloseModal = () => {
        setShowModal(false);
        // Clear the editProfile state when closing the modal
        setEditProfile({
            name: "",
            email: "",
            number: "",
            address: "",
        });
    };

    const handleEditProfile = async () => {
            const response = await  updateProfile(profile.id,editProfile);
            setShowModal(false)
        fetchUserProfile();
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setEditProfile((prevProfile) => ({
            ...prevProfile,
            [name]: value,
        }));
    };

    return (
        <>
            <div className="container mt-5">
                <div className="row">
                    <div className="col-md-6 offset-md-3">
                        <div className="card text-center">
                            <div className="card-header align-text-bottom-center" style={{ background: "#f93" }}>
                                <h5 className="card-title text-white">My Profile</h5>
                            </div>
                            <div className="card-body d-flex">
                                <div className="flex-fill text-start">
                                    <h6 className="mb-2">Name:</h6>
                                    <hr className="my-2" />
                                    <h6 className="mb-2">Email:</h6>
                                    <hr className="my-2" />
                                    <h6 className="mb-2">Mobile Number:</h6>
                                    <hr className="my-2" />
                                    <h6 className="mb-2">Address:</h6>
                                    {/* Add other profile fields as needed */}
                                </div>
                                <div className="flex-fill text-start">
                                    <h6 className="mb-2 fw-normal">{profile.name}</h6>
                                    <hr className="my-2" />
                                    <h6 className="mb-2 fw-normal">{profile.email}</h6>
                                    <hr className="my-2" />
                                    <h6 className="mb-2 fw-normal">{profile.number}</h6>
                                    <hr className="my-2" />
                                    <h6 className="mb-2 fw-normal">{profile.address}</h6>
                                    {/* Add other profile details as needed */}
                                </div>
                            </div>
                            <div className="card-footer">
                                <button className="btn btn-primary" type="button" onClick={handleShowModal}>
                                    Edit
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* The modal */}
            {showModal && (
                <>
                    <div className="modal" tabIndex={-1} role="dialog" style={{ display: "block" }}>
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title">Edit Profile</h5>
                                    <button type="button" className="btn-close" onClick={handleCloseModal} aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <form>
                                        <div className="mb-3">
                                            <label htmlFor="editName" className="form-label">
                                                Name
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control text-dark"
                                                id="editName"
                                                name="name"
                                                value={editProfile.name}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="editEmail" className="form-label">
                                                Email
                                            </label>
                                            <input
                                                type="email"
                                                className="form-control text-dark"
                                                id="editEmail"
                                                name="email"
                                                value={editProfile.email}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="editMobileNumber" className="form-label">
                                                Mobile Number
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control text-dark"
                                                id="editMobileNumber"
                                                name="number"
                                                value={editProfile.number}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="editAddress" className="form-label">
                                                Address
                                            </label>
                                            <textarea
                                                className="form-control text-dark"
                                                id="editAddress"
                                                name="address"
                                                value={editProfile.address}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </form>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" onClick={handleCloseModal}>
                                        Close
                                    </button>
                                    <button type="button" className="btn btn-primary" onClick={handleEditProfile}>
                                        Save changes
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-backdrop fade show" onClick={handleCloseModal}></div>
                </>
            )}
        </>
    );
}

export default Profile;
