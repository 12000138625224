import axios from 'axios'
import { baseUrl } from '../Config';

export const addCategories = async (data) => {
  try {
    return await axios.post(`${baseUrl}Category`, data);
  } catch (error) {
    console.log(`error while calling api ${error}`);
  }
};

export const getCategories = async () => {
  try {
    return await axios.get(`${baseUrl}Category`);
  } catch (error) {
    console.log(`error while calling api ${error}`);
  }
};

export const getSingleCategory = async (id) => {
  try {
    return await axios.get(baseUrl + parseInt(id.id));
  } catch (error) {
    console.log(`error while calling api ${error}`);
  }
};

export const editCategory = async (id, data) => {
  try {
    return await axios.put(baseUrl + parseInt(id.id), data);
  } catch (error) {
    console.log(`error while calling api ${error}`);
  }
};

export const deleteCategory = async (id) => {
  try {
    return await axios.delete(baseUrl + parseInt(id));
  } catch (error) {
    console.log(`error while calling api ${error}`);
  }
};
