import React, { useState } from "react";
import { registerUser } from "../Services/Auth/Register";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { loginCheck } from "../Services/Auth/Login";

export default function (props) {
  let [authMode, setAuthMode] = useState("signin");

  const navigate = useNavigate();

  const [user, setUser] = useState({
    roleId:2,
    name: "",
    email: "",
    password: "",
    address: "",
    number:""
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    setUser({ ...user, [name]: value });
  };

  const changeAuthMode = () => {
    setAuthMode(authMode === "signin" ? "signup" : "signin");
  };

  const register = async (user) => {
    const response = await registerUser(user);
    if (response.status === 200) {
      // navigate(window.location.pathname);
      window.location.reload()
    }
  };

  const login = async (user) => {
    const response = await loginCheck(user);
    if (response.status === 200) {
      localStorage.setItem("user", JSON.stringify(response.data));
      localStorage.setItem("isLoggedIn", true);
      navigate("/");
    }
  };

  useEffect(() => {
    if (localStorage.getItem("isLoggedIn") === true) navigate("/");
  }, []);

  if (authMode === "signin") {
    return (
      <div className="Auth-form-container">
        <div className="Auth-form">
          <div className="Auth-form-content">
            <h3 className="Auth-form-title">Sign In</h3>
            <div className="text-center">
              Not registered yet?{" "}
              <span
                className="link-primary"
                role="button"
                onClick={changeAuthMode}
              >
                Sign Up
              </span>
            </div>
            <div className="form-group mt-3">
              <label>Email address</label>
              <input
              onChange={handleChange}

                type="email"
                name="email"
                className="form-control mt-1"
                placeholder="Enter email"
              />
            </div>
            <div className="form-group mt-3">
              <label>Password</label>
              <input
              onChange={handleChange}

                type="password"
                name="password"
                className="form-control mt-1"
                placeholder="Enter password"
              />
            </div>
            <div className="d-grid gap-2 mt-3">
              <button className="btn btn-primary" onClick={() => login(user)}>
                Submit
              </button>
            </div>
            <p className="text-center mt-2">
              Forgot <a href="#">password?</a>
            </p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="Auth-form-container">
      <div className="Auth-form">
        <div className="Auth-form-content">
          <h3 className="Auth-form-title">Sign In</h3>
          <div className="text-center">
            Already registered?{" "}
            <span
                className="link-primary"
                role="button"
                onClick={changeAuthMode}
            >
              Sign In
            </span>
          </div>
          <div className="form-group mt-3">
            <label>Full Name</label>
            <input
                onChange={handleChange}
                type="text"
                name="name"
                className="form-control mt-1"
                placeholder="e.g Jane Doe"
            />
          </div>
          <div className="form-group mt-3">
            <label>Email address</label>
            <input
                onChange={handleChange}
                type="email"
                name="email"
                className="form-control mt-1"
                placeholder="Email Address"
            />
          </div>
          <div className="form-group mt-3">
            <label>Mobile Number</label>
            <input
                onChange={handleChange}
                type="text"
                name="number"
                className="form-control mt-1"
                placeholder="Mobile Number"
            />
          </div>
          <div className="form-group mt-3">
            <label>Password</label>
            <input
                onChange={handleChange}
                type="password"
                name="password"
                className="form-control mt-1"
                placeholder="Password"
            />
          </div>
          <div className="form-group mt-3">
            <label>Address</label>
            <textarea
                onChange={handleChange}
                type="text"
                name="address"
                className="form-control mt-1"
                placeholder="Address"
            />
          </div>
          <div className="d-grid gap-2 mt-3">
            <button className="btn btn-primary" onClick={() => register(user)}>
              Submit
            </button>
          </div>
          <p className="text-center mt-2">
            {/* Forgot <a href="#">password?</a> */}
          </p>
        </div>
      </div>
    </div>
  );
}
