import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {
    deleteProductFromCart,
    editProductInCart,
    getAllOrderedProductsFromCartAccUSer,
    getSingleProductFromCart,
} from "../Services/Cart/api";
import {user} from "../Services/Config";
import { baseUrl } from '../Services/Config';


function Orders() {
    const navigate = useNavigate();
    const [products, setProducts] = useState([]);
    const [countOfProduct, setCountOfProduct] = useState(0);
    const [totalPrice, setTotalPrice] = useState(0);

    const [count, setCount] = useState(0);
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);
    const [loading, setLoading] = useState(true);
    const options = ["Price", "Name"];

    const goBack = () => {
        navigate("/AllProduct");
    };
    const price = 10; // Set the price per item

    const updateTotalPrice = () => {
        return (count * price).toFixed(2);
    };

    const handleIncrementInQuantity = async (id) => {
        const singleProductResponse = await getSingleProductFromCart(id);
        const singleProduct = singleProductResponse.data;
        const data = {
            id: singleProduct.id,
            price: singleProduct.price + singleProduct.product?.price,
            quantity: singleProduct.quantity + 1,
            date: singleProduct.date,
            status: singleProduct.status,
            userId: singleProduct.userId,
            productId: singleProduct.productId,
        };

        const response = await editProductInCart(id, data);
        if (response.status === 200) {
            getCartProducts(user.id);
        }
    };

    const handleDecrementInQuantity = async (id) => {
        const singleProductResponse = await getSingleProductFromCart(id);
        const singleProduct = singleProductResponse.data;

        const data = {
            id: singleProduct.id,
            price: singleProduct.price - singleProduct.product?.price,
            quantity: singleProduct.quantity - 1,
            date: singleProduct.date,
            status: singleProduct.status,
            userId: singleProduct.userId,
            productId: singleProduct.productId,
        };

        if (singleProduct.quantity > 0) {
            const response = await editProductInCart(id, data);
            if (response.status === 200) {
                getCartProducts(user.id);
            }
        }
    };

    const handleToggle = () => {
        setIsOpen(!isOpen);
        console.log(isOpen);
    };

    const handleSelect = (option) => {
        setSelectedOption(option);
        setIsOpen(false);
    };

    const getCartProducts = async (id) => {
        const response = await getAllOrderedProductsFromCartAccUSer(id);
        if (response.status === 200) {
            setTimeout(() => {
                setProducts(response.data);
                setCountOfProduct(response.data.length);
                setLoading(false);
            }, 1000)
        }
    };

    const deleteSingleProductFromCart = async (id) => {
        const response = await deleteProductFromCart(id);
        if (response.status === 200) {
            getCartProducts(user.id);
        }
    };

    useEffect(() => {
        getCartProducts(user.id);
    }, []);

    useEffect(() => {
        // Calculate total price after products state is updated
        const totalPrice = products.reduce((acc, singleProduct) => acc + singleProduct.price, 0);
        setTotalPrice(totalPrice);
    }, [products]);

    return (
        <>
            <div>
                {loading ? (
                    <div className="d-flex justify-content-center align-items-center vh-100">
                        <div className="spinner-border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>
                ) : (
                    <section className="h-100 h-custom">
                        <div className="container py-5 h-100">
                            <div className="row d-flex justify-content-center align-items-center h-100">
                                <div className="col-lg-8 col-sm-12 w-100">
                                    <div className="card shadow">
                                        <div className="card-body p-4">
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <h5 className="mb-3">
                                                        <button
                                                            onClick={goBack}
                                                            className="text-body"
                                                            style={{background: "none", border: "none"}}
                                                        >
                                                            <i className="fas fa-long-arrow-alt-left me-2"/>
                                                            Continue shopping
                                                        </button>
                                                    </h5>
                                                    <hr/>

                                                    {products.map((singleProduct) => {
                                                        return (
                                                            <div className="card mb-3" key={singleProduct.id}>
                                                                <div className="card-body">
                                                                    <div className="row align-items-center">
                                                                        <div
                                                                            className="col-12 col-md-6 col-lg-3 text-center">
                                                                            <img
                                                                                src={`${baseUrl}uploads/${singleProduct.product.imageName}`}
                                                                                className=" rounded-3"
                                                                                alt="Shopping item"
                                                                                style={{
                                                                                    width: "100%",
                                                                                    height:"80px",
                                                                                    maxWidth: "120px"
                                                                                }}
                                                                            />
                                                                        </div>
                                                                        <div className="col-12 col-md-6 col-lg-3">
                                                                            <h5>{singleProduct.product.name}</h5>
                                                                        </div>
                                                                        <div
                                                                            className="col-12 col-md-6 col-lg-3 mt-3 mt-md-0 text-center">
                                                                            <div
                                                                                className="d-flex justify-content-center align-items-center">

                                                                                <h5
                                                                                    id="count"
                                                                                    className="fw-normal mb-0 mx-3 py-1"
                                                                                >
                                                                                    Qty . {singleProduct.quantity}
                                                                                </h5>

                                                                            </div>
                                                                        </div>
                                                                        <div
                                                                            className="col-12 col-md-6 col-lg-3 mt-3 mt-md-0 text-center">
                                                                            <div
                                                                                className="d-flex justify-content-center align-items-center">
                                                                                <h5 className="mx-4">
                                                                                    ₹ {singleProduct.price.toFixed(2)}
                                                                                </h5>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        );
                                                    })}
                                                    {/* Repeat the card for other items */}
                                                </div>
                                                <div className="d-flex justify-content-end align-items-center mb-4">
                                                        <div className="align-content-center">
                                                            <span className="fw-bold text-dark fs-4">Total : </span>
                                                            <span className="text-primary fw-bold fs-4">
                          ₹ {totalPrice.toFixed(2)}
                        </span>
                                                        </div>
                                                </div>
                                                {/* <div className="col-lg-5 mt-4">
                      <div className="card bg-primary text-white rounded-3">
                        <div className="card-body">
                          <div className="d-flex justify-content-between align-items-center mb-4">
                            <h5 className="mb-0">Card details</h5>
                            <img
                              src="https://mdbcdn.b-cdn.net${baseUrl}uploads/Photos/Avatars/avatar-6.webp"
                              className=" rounded-3"
                              style={{ width: 45 }}
                              alt="Avatar"
                            />
                          </div>
                          <p className="small mb-2">Card type</p>
                          <a href="#!" type="submit" className="text-white">
                            <i className="fab fa-cc-mastercard fa-2x me-2" />
                          </a>
                          <a href="#!" type="submit" className="text-white">
                            <i className="fab fa-cc-visa fa-2x me-2" />
                          </a>
                          <a href="#!" type="submit" className="text-white">
                            <i className="fab fa-cc-amex fa-2x me-2" />
                          </a>
                          <a href="#!" type="submit" className="text-white">
                            <i className="fab fa-cc-paypal fa-2x" />
                          </a>
                          <form className="mt-4">
                            <div className="form-outline form-white mb-4">
                              <input
                                type="text"
                                id="typeName"
                                className="form-control form-control-lg"
                                siez={17}
                                placeholder="Cardholder's Name"
                              />
                              <label className="form-label" htmlFor="typeName">
                                Cardholder's Name
                              </label>
                            </div>
                            <div className="form-outline form-white mb-4">
                              <input
                                type="text"
                                id="typeText"
                                className="form-control form-control-lg"
                                siez={17}
                                placeholder="1234 5678 9012 3457"
                                minLength={19}
                                maxLength={19}
                              />
                              <label className="form-label" htmlFor="typeText">
                                Card Number
                              </label>
                            </div>
                            <div className="row mb-4">
                              <div className="col-md-6">
                                <div className="form-outline form-white">
                                  <input
                                    type="text"
                                    id="typeExp"
                                    className="form-control form-control-lg"
                                    placeholder="MM/YYYY"
                                    size={7}
                                    minLength={7}
                                    maxLength={7}
                                  />
                                  <label
                                    className="form-label"
                                    htmlFor="typeExp"
                                  >
                                    Expiration
                                  </label>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-outline form-white">
                                  <input
                                    type="password"
                                    id="typeText"
                                    className="form-control form-control-lg"
                                    placeholder="●●●"
                                    size={1}
                                    minLength={3}
                                    maxLength={3}
                                  />
                                  <label
                                    className="form-label"
                                    htmlFor="typeText"
                                  >
                                    Cvv
                                  </label>
                                </div>
                              </div>
                            </div>
                          </form>
                          <hr className="my-4" />
                          <div className="d-flex justify-content-between">
                            <p className="mb-2">Subtotal</p>
                            <p className="mb-2">$4798.00</p>
                          </div>
                          <div className="d-flex justify-content-between">
                            <p className="mb-2">Shipping</p>
                            <p className="mb-2">$20.00</p>
                          </div>
                          <div className="d-flex justify-content-between mb-4">
                            <p className="mb-2">Total(Incl. taxes)</p>
                            <p className="mb-2">$4818.00</p>
                          </div>
                          <button
                            type="button"
                            className="btn btn-block btn-lg text-white"
                            style={{ background: "#f93" }}
                          >
                            <div className="d-flex justify-content-between">
                              <span>$4818.00</span>
                              <span>
                                Checkout{" "}
                                <i className="fas fa-long-arrow-alt-right ms-2" />
                              </span>
                            </div>
                          </button>
                        </div>
                      </div>
                    </div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                )}
            </div>
        </>
    );
}

export default Orders;
