import {useEffect, useState} from "react";
import "./App.css";

import Footer from "./components/Footer";
import Header from "./components/Header";
import {Outlet, useLocation} from "react-router-dom";
import {getUserProfile} from "./Services/Auth/profile";
import {user} from "./Services/Config";

function App() {
    const location = useLocation();
    const [isLoggedIn, setIsLoggedIn] = useState(false);


    useEffect(() => {
        localStorage.getItem("isLoggedIn") === "true"
            ? setIsLoggedIn(true)
            : setIsLoggedIn(false);
    }, [location.pathname]);

    return (
        <>
            <Header isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} />
            <Outlet/>
            <Footer/>
        </>
    );
}

export default App;
