import React from "react";

export default function About() {
  return (
    <div>
      <div className="container-fluid about pt-5">
        <div className="container">
          <div className="row gx-5">
            <div className="col-lg-6 mb-5 mb-lg-0">
              <div className="d-flex border border-5 border-primary">
                <img
                  className="mt-auto mx-auto w-100"
                  src="img/about.jpg"
                  alt="about"
                />
              </div>
              <div className="row gx-5 gy-4 mt-4">
                <div className="col-sm-6">
                  <i className="fa fa-seedling display-1 text-secondary" />
                  <h4>100% Organic</h4>
                  <p className="mb-0">
                    Indulge in our 100% organic dairy delights for pure, wholesome goodness
                  </p>
                </div>
                <div className="col-sm-6">
                  <i className="fa fa-award display-1 text-secondary" />
                  <h4>Award Winning</h4>
                  <p className="mb-0">
                    Discover our Award-Winning dairy delights for an unforgettable culinary journey
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 pb-5">
              <div className="mb-3 pb-2">
                <h1 className="display-5">
                  Marvel Agro & Dairy Farm <br />
                </h1>
                <h4>
                  "Banshi Farm", Lakhabaval, Nr. Shivalik Shine Party Plot
                </h4>
              </div>
              <h6 className="text-primary text-uppercase">History :</h6>
              <p className="mb-4">
                The foundation was laid down in 2021 with intension to provide
                pure and natural products at a door steps. Scarcity of 100% pure
                Gir Cow milk & Organic Products nearby area was initiation of
                foundation stone. MARWELL AGRO & DAIRY FARM is non-NGO type
                partnership Firm. Firm is ZERO CARBON EMMISSION with 100%
                recycle renewal energy-based production line. We are using solar
                roof top, own cultivation of green fodder using waste generation
                of herds. Pesticides & "JIVAMRUT" Phosphate based fertilizers.
              </p>
              <h6 className="text-primary text-uppercase">
                Industry Experience :
              </h6>
              <p className="mb-4">
                4 partners have diversified experience in their own fields but
                none of them having relevant experience and that's the beauty of
                establishment of the firm. "40+" - experience of all individual
                partners has been converted into "4T+" (Trust, Transparency,
                Target & Taste ). We have transformed this reality into four
                pillars of our firm.
              </p>
              <h6 className="text-primary text-uppercase">Capacity :</h6>
              <p className="mb-4">
                Current population of 25 herds with an average milk production
                of 60 Ltr/Day since inception is now targeting to doubling the
                capacity to reach 100 Ltr/Day in near future. Expansion will not
                only increase the customer base but will also enhance the feed
                for other unique products under development at site.
              </p>
              <h6 className="text-primary text-uppercase">Product Range :</h6>
              <p className="mb-4">
                ● 100% pure GIR COW milk <br /> ● Vermicompose Fertilizer, Wash
                Water, JIVAMRUT <br />● Cow Dung Cakes & Gau Mutra <br />● Idols
                made from cow dung & incense sticks <br />● Organic Vegetables &
                Fruits <br />● Butter Milk & Ghee - product line without using
                any mechanical device to get the pure taste with flavor of
                well-established Indian Style.
              </p>
              <h6 className="text-primary text-uppercase">Our Mission :</h6>
              <p className="mb-4">
                To Elevate Your Culinary Experience and Enrich Your Lifestyle, We Unleash the Natural Goodness and Potential of Our Partner Farms. With Confidence, We Strive for Exponential Growth to Meet and Exceed Your Dairy Delights
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
