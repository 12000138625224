import axios from 'axios'
import { baseUrl } from '../Config';

export const addProductToCart = async (data) => {
  try {
    return await axios.post(`${baseUrl}Cart`, data);
  } catch (error) {
    console.log(`error while calling api ${error}`);
  }
};

export const getAllProductsFromCart = async () => {
  try {
    return await axios.get(`${baseUrl}Cart`);
  } catch (error) {
    console.log(`error while calling api ${error}`);
  }
};


export const getAllProductsFromCartAccUSer = async (id) => {
  try {
    return await axios.get(`${baseUrl}FetchCartAccUser/`+ parseInt(id));
  } catch (error) {
    console.log(`error while calling api ${error}`);
  }
};

export const getAllOrderedProductsFromCartAccUSer = async (id) => {
  try {
    return await axios.get(`${baseUrl}FetchOrderedCartAccUser/`+ parseInt(id));
  } catch (error) {
    console.log(`error while calling api ${error}`);
  }
};

export const getSingleProductFromCart = async (id) => {
  try {
    console.log(id)
    return await axios.get(`${baseUrl}Cart/` + parseInt(id));
  } catch (error) {
    console.log(`error while calling api ${error}`);
  }
};

export const editProductInCart = async (id, data) => {
  try {
    return await axios.put(`${baseUrl}Cart/` + parseInt(id), data);
  } catch (error) {
    console.log(`error while calling api ${error}`);
  }
};

export const deleteProductFromCart = async (id) => {
  try {
    return await axios.delete(`${baseUrl}Cart/` + parseInt(id));
  } catch (error) {
    console.log(`error while calling api ${error}`);
  }
};

export const placeOrder = async (id) => {
  try {
    return await axios.put(`${baseUrl}Ordered/${id}`);
  } catch (error) {
    console.log(`error while calling api ${error}`);
  }
};