import React, { useEffect, useState } from "react";
import { getProductByCategory } from "../Services/Products/api";
import { Link, useParams } from "react-router-dom";
import { baseUrl } from "../Services/Config";

export default function SingleCategory() {
  const { CategoryId } = useParams();
  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState([]);

  useEffect(() => {
    getAllproducts(CategoryId);
  }, [CategoryId]); // Watch for changes in CategoryId

  const getAllproducts = async (id) => {
    let response = await getProductByCategory(id);
    setProducts([]);
    setLoading(true);

    setTimeout(() => {
      setProducts(response.data);
      setLoading(false);
    }, 1000);
  };
  return (
    <>
      <div>
        {loading ? (
          <div className="d-flex justify-content-center align-items-center vh-100">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        ) : (
          <div className="container-fluid py-5">
            <div className="container">
              <div
                className="mx-auto text-center mb-5"
                style={{ maxWidth: "500px" }}
              >
                <h6 className="text-primary text-uppercase">Products</h6>
                <h1 className="display-5">Our Fresh & Organic Products</h1>
              </div>
              <div className="container-img">
                <div className="row">
                  {products.map((product) => {
                    return (
                      <div
                        key={product.id}
                        className="col-lg-3 product-item position-relative bg-white d-flex flex-column text-center"
                      >
                        <Link to={`/ProductDetail/${product.id}`}>
                          <img
                            className="img-fluid mb-4"
                            src={`${baseUrl}uploads/${product.imageName}`}
                            alt={`${product.name}`}
                          />
                          <h6 className="mb-3">{product.name}</h6>
                        </Link>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
