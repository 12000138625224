import React, {useEffect, useState} from "react";
import ReactImageMagnify from "react-image-magnify";
import {useNavigate, useParams} from "react-router-dom";
import {getSingleProduct} from "../Services/Products/api";
import {addProductToCart} from "../Services/Cart/api";
import {user} from "../Services/Config";
import { baseUrl } from '../Services/Config';


function ProductDetail() {
    const id = useParams("ProductId");
    const [product, setProduct] = useState([]);
    const [loading, setLoading] = useState(true);


    const navigate = useNavigate();

    useEffect(() => {
        getproduct(id.ProductId);
    }, []);

    const getproduct = async (id) => {
        let response = await getSingleProduct(id);
        setTimeout(() => {
            setProduct(response.data);
            setLoading(false);
        }, 1000)
    };

    const date = new Date();
    const formattedDate = date.toLocaleString('en-US', {hour12: false}) + ' ' + date.getTimezoneOffset();


    const productForCartData = {
        price: product.price,
        quantity: 1,
        date: formattedDate,
        status: 'pending',
        userId: user?.id,
        productId: product.id,
    }


    const imageUrl = `${baseUrl}uploads/${product.imageName}`;

    // Get image dimensions and calculate the largeImage width and height
    const [imageDimensions, setImageDimensions] = useState({
        width: 0, height: 0,
    });

    useEffect(() => {
        const image = new Image();
        image.src = imageUrl;
        image.onload = () => {
            setImageDimensions({width: image.width, height: image.height});
        };
    }, [imageUrl]);

    const largeImage = {
        src: imageUrl, width: imageDimensions.width, height: imageDimensions.height,
    };

    const addToCart = async (data) => {
        if (user === null) {
            return navigate("/Login")
        }

        const response = await addProductToCart(data);
        if (response.status === 200) {
            return navigate("/Cart");
        }
    }

    return (
        <div>
            {loading ? (
                <div className="d-flex justify-content-center align-items-center vh-100">
                    <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            ) : (
                <div className="container-fluid about pt-5">
                    <div className="container">
                        <div className="row gx-5">
                            <div className="col-lg-6 mb-5 mb-lg-0">
                                <div className="d-flex border border-5 border-primary">
                                    <video className="mt-auto mx-auto w-100" controls autoPlay>
                                        {/* <source src="video/video (2160p).mp4" type="video/mp4" /> */}
                                        <source src="" type="video/mp4"/>
                                    </video>
                                </div>
                                <div
                                    className="d-flex border border-5 border-primary mt-4"
                                    style={{width: "100%"}}
                                >
                                    <div
                                        style={{
                                            display: "flex", width: "100%",
                                        }}
                                    >
                                        <ReactImageMagnify
                                            className="w-100"
                                            style={{width: "100%"}}
                                            {...{
                                                smallImage: {
                                                    alt: "Random Product Image", isFluidWidth: true, src: imageUrl,
                                                }, largeImage: {
                                                    src: imageUrl, width: 1500, height: 1500,
                                                },
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 pb-5">
                                <div className="mb-3 pb-2 d-flex gap-5">
                                    <h1 className="display-5">
                                        {product.name} <br/>
                                    </h1>
                                    <div>
                                        <button onClick={() => addToCart(productForCartData)}
                                                className="p-2 mt-2 btn btn-primary">
                                            Add to Cart
                                        </button>
                                    </div>
                                </div>
                                <h6 className="text-primary text-uppercase">Description :</h6>
                                <p className="mb-4">{product.description}</p>
                                <h6 className="text-primary text-uppercase">Price :</h6>
                                <p className="mb-4">₹ {product.price}</p>
                                <h6 className="text-primary text-uppercase">Product Range :</h6>
                                <p className="mb-4">
                                    ● 100% pure GIR COW milk <br/> ● Vermicompose Fertilizer, Wash
                                    Water, JIVAMRUT <br/>● Cow Dung Cakes & Gau Mutra <br/>● Idols
                                    made from cow dung & incense sticks <br/>● Organic Vegetables &
                                    Fruits <br/>● Butter Milk & Ghee - product line without using
                                    any mechanical device to get the pure taste with flavor of
                                    well-established Indian Style.
                                </p>
                                <h6 className="text-primary text-uppercase">Our Mission :</h6>
                                <p className="mb-4">
                                    "To Deliver Best from Soil to Plate" To improve customer need
                                    and lifestyle by unlocking the nature gift and potential of
                                    partner's dream, we are confident to achieve the company goal on
                                    an exponential growth.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>);
}

export default ProductDetail;
