import axios from 'axios'
import { baseUrl } from '../Config';

export const getUserProfile = async (id) => {
    try {
        return await axios.get(`${baseUrl}User/${parseInt(id)}`);
    } catch (error) {
        console.log(`error while calling api ${error}`);
    }
};

export const updateProfile = async (id,data) => {
    try {
        return await axios.put(`${baseUrl}User/${parseInt(id)}`, data);
    } catch (error) {
        console.log(`error while calling api ${error}`);
    }
};